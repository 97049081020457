
import React, { useState } from 'react'
import styled from 'styled-components'
import Navigation from './Navigation';
import BlogArticle from './BlogArticle';
import BlogArticleTwo from './BlogArticleTwo';
// import { AOSInit } from './aos';
 


const StyledApp  = styled.div`

main {
  scroll-behavior: smooth;
  
}

.lilita-one-regular {
  font-family: "Lilita One", sans-serif;
  font-weight: 400;
  font-style: normal;
}



.mouse-memoirs-regular {
  font-family: "Mouse Memoirs", sans-serif;
  font-weight: 400;
  font-style: normal;
}

 @keyframes wave {
  0%, 100% {
    transform: skew(0);
  }
  50% {
    transform: skew(0, 15deg);
  }
}


.dbody {
  scroll-behavior: smooth;
  display: flex;
  justify-content: center;
  background-color: #304Ea5;
  position: absolute;
  top:0;
  width: 100%;
  z-index: -1;
  opacity: 0.42;
  filter: contrast(2) saturate(2)
}

.container {
  scroll-behavior: smooth;
  position: relative;
  top: -70px;
  width: 500px;
  height: 500px;
}

.arc {
  position: absolute;
  right: -3rem;
  width: 120%;
  height: 100%;
}
.subhero img {
  width:33vw;
  padding:2.3em;
  border-radius:14px;
  transform:translate3d(1.2em,0,0);
}
@media screen and (max-width: 700px){
  .arc {
    position: absolute;
    top:-7.5em;
    right: 0;
    width: 100%;
    height: 100%;
  }
  #d-castle {
    zoom:0.8;
    margin: 40px 0 5em 0;
  }
  
  .subhero img {
    padding: 0;
    width: 27.5vw;
    transform:translate3d(0,0,0);
}

}
.arc circle {
  cx: 50%;
  cy: 100%;
  r: 53.5%;
  fill: transparent;
  stroke-width: 2;
  stroke: white;
  transform-origin: 50% 50%;
  transform-box: fill-box;
  stroke-dasharray: 1870;
  stroke-dashoffset: 1870;
  animation: offset 2.5s ease-out 1s forwards;
}

@keyframes offset {
  to {
    stroke-dashoffset: 0;
  }
}
.castle {
  background-color: #387FE9;
}

.portcullis {
  position: absolute;
  left: 41%;
  bottom: -7%;
  background: #304Ea5;
  border-radius: 50% 50% 0 0/50% 50% 0 0;
  height: 20%;
  width: 20%;
  transition: border-radius 0.3s;
  z-index: 2;
}
.portcullis:hover {
  border-radius: 50%;
}
.portcullis:hover::before {
  /* background-color: #304Ea5; */
  border-radius: 50%;
  width: 60%;
  height: 60%;
}

.base-1 {
  position: absolute;
  left: 15.5%;
  bottom: 0;
  width: 70%;
  height: 10%;
}

.base-2 {
  position: absolute;
  left: 20%;
  bottom: 9%;
  width: 65%;
  height: 7%;
}

.base-3 {
  position: absolute;
  left: 24%;
  bottom: 15%;
  width: 55%;
  height: 6%;
}

.base-4 {
  position: absolute;
  left: 24%;
  bottom: 20%;
  width: 50%;
  height: 6%;
}
.base-4::after {
  position: absolute;
  bottom: 50%;
  left: 76%;
  border-top: 0px solid transparent;
  border-left: 30px solid transparent;
  border-right: 30px solid transparent;
  border-bottom: 60px solid #387FE9;
  content: "";
  display: block;
}

.base-tower {
  position: absolute;
  width: 0;
  height: 0;
  border-top: 0px solid transparent;
  border-left: 30px solid transparent;
  border-right: 30px solid transparent;
  border-bottom: 60px solid #387FE9;
}

.left {
  bottom: 9.5%;
  left: 12.5%;
}
.left::after {
  position: absolute;
  bottom: 10%;
  right: 10%;
  content: "";
  display: block;
  transform-origin: right;
  animation: 0.3s wave 0.2s infinite;
  background: #387FE9;

  height: 0.6rem;
  width: 1.2rem;
}

.right {
  bottom: 9.5%;
  left: 76.5%;
}
.right::after {
  position: absolute;
  bottom: 10%;
  right: 10%;
  content: "";
  display: block;
  transform-origin: right;
  animation: 0.3s wave 0.4s infinite;
  background: #387FE9;
  -webkit-clip-path: polygon(100% 0, 0 47%, 100% 100%);
  clip-path: polygon(100% 0, 0 47%, 100% 100%);
  height: 0.6rem;
  width: 1.2rem;
}

.mid-tower-left {
  position: absolute;
  left: 24%;
  bottom: 15%;
  width: 9%;
  height: 13%;
}
.mid-tower-left::after {
  position: absolute;
  top: -20%;
  left: -13px;
  border-top: 45px solid #387FE9;
  border-left: 35px solid transparent;
  border-right: 35px solid transparent;
  border-bottom: 0px solid transparent;
  content: "";
  display: block;
}

.mid-tower-left-roof {
  position: absolute;
  left: 22.3%;
  bottom: 30%;
  border-top: 0px solid transparent;
  border-left: 30px solid transparent;
  border-right: 30px solid transparent;
  border-bottom: 60px solid #387FE9;
}
.mid-tower-left-roof::after {
  position: absolute;
  bottom: 10%;
  right: 10%;
  content: "";
  display: block;
  transform-origin: right;
  animation: 0.3s wave 0.6s infinite;
  background: #387FE9;
  -webkit-clip-path: polygon(100% 0, 0 47%, 100% 100%);
  clip-path: polygon(100% 0, 0 47%, 100% 100%);
  height: 0.6rem;
  width: 1.2rem;
}

.mid-tower-right {
  position: absolute;
  left: 71%;
  bottom: 20.8%;
  border-top: 0px solid transparent;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-bottom: 70px solid #387FE9;
}
.mid-tower-right::after {
  position: absolute;
  bottom: 10%;
  right: 0;
  content: "";
  display: block;
  transform-origin: right;
  animation: 0.3s wave 0.8s infinite;
  background: #387FE9;
  -webkit-clip-path: polygon(100% 0, 0 47%, 100% 100%);
  clip-path: polygon(100% 0, 0 47%, 100% 100%);
  height: 0.6rem;
  width: 1.2rem;
}

.level-2-tower-left {
  position: absolute;
  left: 34%;
  bottom: 25%;
  border-top: 0px solid transparent;
  border-left: 23px solid transparent;
  border-right: 23px solid transparent;
  border-bottom: 70px solid #387FE9;
}

.level-3-tower {
  position: absolute;
  left: 65%;
  bottom: 26%;
  width: 5%;
  height: 12%;
}

.level-3-tower-roof {
  position: absolute;
  bottom: 37%;
  right: 28.5%;
  border-top: 0px solid transparent;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-bottom: 40px solid #387FE9;
}

.keep {
  position: absolute;
  left: 42.5%;
  bottom: 25%;
  width: 23%;
  height: 10.5%;
}

.side-tower {
  position: absolute;
  bottom: 20%;
  left: 40%;
  border-top: 40px solid #387FE9;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-bottom: 0px solid transparent;
  width: 5px;
}

.side-tower.roof {
  position: absolute;
  bottom: 38%;
  left: 41%;
  border-top: 0px solid transparent;
  border-left: 18px solid transparent;
  border-right: 18px solid transparent;
  border-bottom: 45px solid #387FE9;
  width: 0;
}
.side-tower.roof::after {
  position: absolute;
  bottom: 1%;
  right: 10%;
  content: "";
  display: block;
  transform-origin: right;
  animation: 0.3s wave 0.1s infinite;
  background: #387FE9;
  -webkit-clip-path: polygon(100% 0, 0 47%, 100% 100%);
  clip-path: polygon(100% 0, 0 47%, 100% 100%);
  height: 0.6rem;
  width: 1.2rem;
}

.keep-main {
  position: absolute;
  left: 49.3%;
  bottom: 35%;
  width: 14%;
  height: 16%;
  -webkit-clip-path: polygon(50% 0%, 100% 0, 100% 100%, 0 100%, 0 49%, 50% 49%);
  clip-path: polygon(50% 0%, 100% 0, 100% 100%, 0 100%, 0 49%, 50% 49%);
}

.main-side-roof {
  position: absolute;
  left: 49.3%;
  bottom: 43%;
  border-top: 0px solid transparent;
  border-left: 18px solid transparent;
  border-right: 18px solid transparent;
  border-bottom: 45px solid #387FE9;
  width: 0;
}

.main-tower {
  position: absolute;
  left: 54.7%;
  bottom: 50%;
  width: 10%;
  height: 10%;
  -webkit-clip-path: polygon(16% 0, 85% 0, 85% 38%, 100% 38%, 86% 100%, 15% 100%, 0 37%, 16% 37%);
  clip-path: polygon(16% 0, 85% 0, 85% 38%, 100% 38%, 86% 100%, 15% 100%, 0 37%, 16% 37%);
}

.main-tower-roof {
  position: absolute;
  left: 54.7%;
  bottom: 59%;
  border-top: 0px solid transparent;
  border-left: 25px solid transparent;
  border-right: 25px solid transparent;
  border-bottom: 45px solid #387FE9;
  width: 0;
}
.main-tower-roof::after {
  position: absolute;
  bottom: 10%;
  right: 10%;
  content: "";
  display: block;
  transform-origin: right;
  animation: 0.3s wave 0.3s infinite;
  background: #387FE9;
  -webkit-clip-path: polygon(100% 0, 0 47%, 100% 100%);
  clip-path: polygon(100% 0, 0 47%, 100% 100%);
  height: 0.6rem;
  width: 1.2rem;
}


/* #_#_#_#_#_#_#_#_# */


a:hover {
    text-decoration: none;
}

.landing-img {
    line-height: 1.67;
    background-color: rgba(30, 205, 205, 0.271);
    background-blend-mode: multiply;
    background-size: cover;
    background-position: center;
    min-height: 500px;
    background-position: center;
    background-attachment: fixed !important;;
}

.landing-img-2 {
    /* background-image: url(https://deih43ym53wif.cloudfront.net/4-Positano_F315EB_7c12777b80.jpeg), linear-gradient(to bottom, #323232bb 0%, #3f3f3fb6 40%, #1c1c1c77 150%), linear-gradient(to top, rgba(255, 255, 255, 0.171) 0%, rgba(0, 0, 0, 0.171) 200%); */
    background-blend-mode: multiply;
    background-size: cover;
    background-position: center;
    min-height: 500px;
    background-position: center;
    background-attachment: fixed !important;;
}

.landing-text {
    position: absolute;
    padding-top: 130px;
    padding-left: 12vw;
    color: #222
}
.landing-text p {
  max-width: 77vw
}

.landing-text-2 {
    margin-left: auto;
    margin-right: auto;
    padding-top: 130px;
}

.center {
    text-align: center;
}

:root {
    --teal-green: #00C9A8;
}

.plan-btn {
    background-color: transparent;
    border: 2px solid white;
}

.plan-btn:hover {
    background-color: var(--teal-green);
}

.text-teal {
    color: var(--teal-green);
}



.back {
    background-image: url('https://dl3.pushbulletusercontent.com/oaR8hQPx2c4qmfoZMK3H9yrQkrTFjA10/image.png'), linear-gradient(to bottom, #2b2929e3 0%, #2b2929e3 40%, #2b2929e3 150%), linear-gradient(to top, rgba(255, 255, 255, 0.171) 0%, rgba(0, 0, 0, 0.171) 200%);
    background-blend-mode: multiply;
    background-size: cover;
    min-height: 500px;
    background-position: top;
    background-attachment: fixed !important;;

}

.back-2 {
    background-image: url('https://dl3.pushbulletusercontent.com/LjLpYcP3WC7oD8xZn6E9HCTBWJDlttBr/image.png'), linear-gradient(to bottom, #2b2929e3 0%, #2b2929e3 40%, #2b2929e3 150%), linear-gradient(to top, rgba(255, 255, 255, 0.171) 0%, rgba(0, 0, 0, 0.171) 200%);
    background-blend-mode: multiply;
    background-size: cover;
    background-position: center;
    min-height: 500px;
    background-attachment: fixed !important;;

}




@media screen and (max-width: 700px){
  

.back {
  display:  none;

}

.back-2 {
   display: none;

}

}

.btn-style-three:before {
    position: absolute;
    content: '';
    left: 10px;
    top: 10px;
    z-index: -1;
    right: -10px;
    bottom: -10px;
    background: url(https://i.ibb.co/DKn55Qz/pattern-1.jpg) repeat;
}
.btn-style-three:hover {
    color: #ffffff;
    background: #75d2f3;
}
 .btn-style-three {
    position: relative;
    line-height: 24px;
    color: #252525;
    font-size: 15px;
    font-weight: 700;
    background: none;
    display: inline-block;
    padding: 11px 40px;
    background-color: #ffffff;
    text-transform: capitalize;
    border: 2px solid #75d2f3;
/*    font-family: 'Arimo', sans-serif;*/
}

/* #_#_#_#_#_#_#_#_#_#_#_##__# */
@import url('https://fonts.googleapis.com/css?family=Roboto+Slab:100,300,400,700');
@import url('https://fonts.googleapis.com/css?family=Raleway:300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i');



.grid-body  {
   /* font-family: 'Roboto Slab', serif; */
    margin: 0;
  width: 100%;
height: 100%;
    padding: 5em 2em;

}

.grid-body {
  background-color: #D2DBDD;
  display: flex;
  display: -webkit-flex;
  -webkit-justify-content: center;
  -webkit-align-items: center;
  justify-content: center;
  align-items: center;
}

.cards {
    width: 100%;
    display: flex;
    display: -webkit-flex;
    justify-content: center;
    -webkit-justify-content: center;
    max-width: 820px;
}

.card--1 .card__img, .card--1 .card__img--hover {
    background-image: url('https://images.pexels.com/photos/3411135/pexels-photo-3411135.jpeg?cs=srgb&dl=pexels-thatguycraig000-3411135.jpg&fm=jpg');
}

.card--2 .card__img, .card--2 .card__img--hover {
    background-image: url('https://dl3.pushbulletusercontent.com/zFgpVWOZ9EBGn9ebpH0ozxj3fRR900dS/image.png');
}

.card__like {
    width: 18px;
}

.card__clock {
    width: 15px;
  vertical-align: middle;
    fill: #AD7D52;
}
.card__time {
    font-size: 12px;
    color: #AD7D52;
    vertical-align: middle;
    margin-left: 5px;
}

.card__clock-info {
    float: right;
}

.card__img {
  visibility: hidden;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    height: 235px;
  border-top-left-radius: 12px;
border-top-right-radius: 12px;
  
}

.card__info-hover {
    position: absolute;
    padding: 16px;
  width: 100%;
  opacity: 0;
  top: 0;
}

.card__img--hover {
  transition: 0.2s all ease-out;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
  position: absolute;
    height: 235px;
  border-top-left-radius: 12px;
border-top-right-radius: 12px;
top: 0;
  
}
.cardi {
  margin-right: 25px;
  transition: all .4s cubic-bezier(0.175, 0.885, 0, 1);
  background-color: #fff;
    width: 33.3%;
  position: relative;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0px 13px 10px -7px rgba(0, 0, 0,0.1);
}

.cardi:hover {
  box-shadow: 0px 30px 18px -8px rgba(0, 0, 0,0.1);
    transform: scale(1.10, 1.10);
}

@media screen and (max-width: 700px){

  .cardi {
  margin-right: 0;
  width: 78vw
}
.cards {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2em;
}

}

.card__info {
z-index: 2;
  background-color: #fff;
  border-bottom-left-radius: 12px;
border-bottom-right-radius: 12px;
   padding: 16px 24px 24px 24px;
}

.card__category {
    /* font-family: 'Raleway', sans-serif; */
    text-transform: uppercase;
    font-size: 13px;
    letter-spacing: 2px;
    font-weight: 500;
  color: #868686;
}

.card__title {
    margin-top: 5px;
    margin-bottom: 10px;
    /* font-family: 'Roboto Slab', serif; */
}

.card__by {
    font-size: 12px;
    /* font-family: 'Raleway', sans-serif; */
    font-weight: 500;
}

.card__author {
    font-weight: 600;
    text-decoration: none;
    color: #AD7D52;
}

.cardi:hover .card__img--hover {
    height: 100%;
    opacity: 0.3;
}

.cardi:hover .card__info {
    background-color: transparent;
    position: relative;
}

.cardi:hover .card__info-hover {
    opacity: 1;
}

.flex {
  display: flex;
}
.row {
  flex-direction: row;
}
.column {
  flex-direction: column;
}

.caption img {
  max-width: 320px;
  margin: auto;
}
.caption span {
  font-size: 0.75em;
  color: #555;
  /* padding-left: 3.75em; */
  margin: auto;
}
@media screen and (max-width: 700px){

.caption span {
  font-size: 0.75em;
  color: #555;
  padding-left: 1.32em;
}

}


.socials-holder {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 10vh;
}

/* Color Variables */
/* Social Icon Mixin */
/* Social Icons */
.social-icons {
  display: flex;
}

.social-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 40px;
  height: 40px;
  margin: 0 0.5rem;
  border-radius: 50%;
  cursor: pointer;
  font-family: "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  font-size: 1.5rem;
  text-decoration: none;
  transition: all 0.15s ease;
}
.social-icon:hover {
  color: #fff;
}
.social-icon:hover .tooltip {
  visibility: visible;
  opacity: 1;
  transform: translate(-50%, -150%);
}
.social-icon:active {
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.5) inset;
}
.social-icon--linkedin {
  background: #006599;
  color: #fff;
}
.social-icon--linkedin .tooltip {
  background: #006599;
  color: currentColor;
}
.social-icon--linkedin .tooltip:after {
  border-top-color: #006599;
}
.social-icon--twitter {
  background: #2b97f1;
  color: #fff;
}
.social-icon--twitter .tooltip {
  background: #2b97f1;
  color: currentColor;
}
.social-icon--twitter .tooltip:after {
  border-top-color: #2b97f1;
}
.social-icon--codepen {
  background: #000;
  color: #fff;
}
.social-icon--codepen .tooltip {
  background: #000;
  color: currentColor;
}
.social-icon--codepen .tooltip:after {
  border-top-color: #000;
}
.social-icon--facebook {
  background: #3b5a9b;
  color: #fff;
}
.social-icon--facebook .tooltip {
  background: #3b5a9b;
  color: currentColor;
}
.social-icon--facebook .tooltip:after {
  border-top-color: #3b5a9b;
}
.social-icon--instagram {
  background: #527fa6;
  color: #fff;
}
.social-icon--instagram .tooltip {
  background: #527fa6;
  color: currentColor;
}
.social-icon--instagram .tooltip:after {
  border-top-color: #527fa6;
}
.social-icon--dribbble {
  background: #ef5a92;
  color: #fff;
}
.social-icon--dribbble .tooltip {
  background: #ef5a92;
  color: currentColor;
}
.social-icon--dribbble .tooltip:after {
  border-top-color: #ef5a92;
}
.social-icon--github {
  background: #4284c0;
  color: #fff;
}
.social-icon--github .tooltip {
  background: #4284c0;
  color: currentColor;
}
.social-icon--github .tooltip:after {
  border-top-color: #4284c0;
}
.social-icon i {
  position: relative;
  top: 1px;
}

/* Tooltips */
.tooltip {
  display: block;
  position: absolute;
  top: 0;
  left: 50%;
  padding: 0.8rem 1rem;
  border-radius: 40px;
  font-size: 0.8rem;
  font-weight: bold;
  opacity: 0;
  pointer-events: none;
  text-transform: uppercase;
  transform: translate(-50%, -100%);
  transition: all 0.3s ease;
  z-index: 1;
}
.tooltip:after {
  display: block;
  position: absolute;
  bottom: 1px;
  left: 50%;
  width: 0;
  height: 0;
  content: "";
  border: solid;
  border-width: 10px 10px 0 10px;
  border-color: transparent;
  transform: translate(-50%, 100%);
}

/* #_#_#_#_#_#_#_#_ */
.mobile-non-parallax {
  display: none;
}
.mobile-non-parallax img {
  padding: 2em 0 1.225rem 0;
}
@media screen and (max-width: 700px) {
  .mobile-non-parallax {
    filter: brightness(0.75) contrast(1.2);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

}

.text-ready {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2em;
  gap: 0.671em;
}

.text-ready h3 {
  font-size: 1.67em;
}

.text-ready h5 {
font-size: 1.1em;
}









.wrapper {
  width: 100%;
  height: auto;
  /* position: absolute;
  top: 0;
  left: 0; */
  background: url('https://res.cloudinary.com/dupqltoku/image/upload/v1606145479/white-carrara-marble-bkg_vvns0p.png'); 
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
}

.row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.column {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
}

.recipe {
  display: flex;
  flex-direction: column;
  flex: 1;
  width:100%;
  height:auto;
  margin-left:-40px;
  background: url('https://publish.purewow.net/wp-content/uploads/sites/2/2023/05/Best-Disney-Character-Dining-Uni.jpg?fit=2050%2C1100');
  background-position: bottom left;
  background-repeat: no-repeat;
  background-size: 100%;
}

.copy{
  display: flex;
  flex-direction: column;
  flex: 1;
  max-height:800px;
  justify-content: center;
 background: url('https://res.cloudinary.com/dupqltoku/image/upload/v1606154319/Rice_Grains_q639ty.svg');
  background-position: top right;
  background-repeat: no-repeat;
  background-size: 50%;
}
.headline{
  height:400px;

 background: url("https://static.wixstatic.com/media/d8461c_28a0ee44ae1b41158524d194d3a5454c~mv2.png/v1/fit/w_2500,h_1330,al_c/d8461c_28a0ee44ae1b41158524d194d3a5454c~mv2.png");
 /* mix-blend-mode: difference; */
 /* filter: invert(1); */
 /* background: url("https://res.cloudinary.com/dupqltoku/image/upload/v1609254913/b4-copy_jg5rwu.svg"); */
  background-position:top center;
  background-repeat: no-repeat;
  background-size: 75%;
  z-index:9999;
  margin-top: 20px;

}
.negative-margin {
  margin-top:-100px;
  justify-content: center;
}
.products{
  width:65%;
  mix-blend-mode: multiply;
}
.products img{
  width: 100%;
  height: auto;
  filter:drop-shadow(3px 3px 5px #000000, 2px 2px 2px #000000);
 
}

/* .products::before {
	filter: drop-shadow(3px 3px 5px #000000);
	mix-blend-mode: multiply;
  content: " ";

	position: absolute;
	top: 0;
	right: 0;
	left: 0;
	bottom: 0;
} */


.ctas{
  display: flex;
  flex-direction:column;
  align-items: center;
  justify-content: center;
  margin:0 auto;
  height:auto;
  width:30%;
 
}
.button {
  padding: 20px;
}
.button--default-inverse {
    font-family: "Superclarendon";
    font-weight: 700;
    letter-spacing: 0;
    font-size: 1.0rem;
    line-height: 1.9rem;
    display: inline-block;
    position: relative;
    text-decoration:none;
    z-index: 1;
    box-sizing: border-box;
    outline: none;
    color: #000;
    cursor: pointer;
    min-width: 150px;
    padding: 11px 22px 12px;
    text-align: center;
    background: #7dbde8
}

/*  */





`;
 
const App = () => {
  const [pageCurrent, setPageCurrent] = useState('main')
  // const [isNavOpen, setIsNavOpen] = useState(false
return (
<StyledApp>

<Navigation />

{pageCurrent === 'blogOne' && <BlogArticle setPageCurrent={setPageCurrent} />}

{pageCurrent === 'blogTwo' && <BlogArticleTwo setPageCurrent={setPageCurrent} />}

{ pageCurrent === 'main' &&

  <div className='dbody'>
<div className="container" id="d-castle">
  <svg className="arc">
    <circle></circle>
  </svg>
  <div className="portcullis"> </div>
  <div className="castle base-1">  </div>
  <div className="castle base-2">  </div>
  <div className="castle base-3">  </div>
  <div className="castle base-4">  </div>
  <div className="base-tower left"></div>
  <div className="base-tower right"></div>
  <div className="castle mid-tower-left"></div>
  <div className="mid-tower-left-roof"></div>
  <div className="mid-tower-right"></div>
  <div className="level-2-tower-left"></div>
  <div className="level-2-tower-right"></div>
  <div className="castle level-3-tower"></div>
  <div className="level-3-tower-roof"></div>
  <div className="castle keep-base"></div>
  <div className="castle keep-main"></div>
  <div className="keep side-tower"></div>
  <div className="keep side-tower roof"></div>
  <div className="castle keep"></div>
  <div className="castle main-tower"></div>
  <div className="main-tower-roof"></div>
  <div className="main-side-roof"></div>
  <div className="keep tower-roof"></div>
</div>
</div>
}


{/* HOME HERO */}


{/* <Layout>
  {props.children}
</Layout> */}

     { pageCurrent === 'main' &&  <main>
        <section>
            <div  className="carousel slide" data-ride="carousel">

                {/* <!-- Indicator --> */}
             

                {/* <!-- slide show --> */}
                <div className="carousel-inner" style={{background:'transparent'}}>
                    <div className="carousel-item active">
                        <div className=" landing-img text-white">
                            <div className="landing-text">
                                <h1 className='lilita-one-regular' style={{fontSize: '1.3em'}}><b> Creating Your Happiest   <br />
                                Disney Journey- With Courtney</b>
                                </h1>
                                <br />
                                <p>committed to making the vacation planning process as stress-free as possible to ensure an 
                                unforgettable and magical vacation experience
                                </p>
                                <br />
                               <a href="https://castleexplorers.typeform.com/courtneyflaska">
                                 <button className="btn btn-style-three"><b>Get a Free Quote </b></button>
                                </a>
                                <br />
                            </div>
                        </div>
<div className="socials-holder" id='contact'>
<div className="social-icons">



  <a href='https://www.instagram.com/courtneys.castle.travels' className="social-icon social-icon--instagram">
    <i className="fa fa-instagram"></i>
    <div className="tooltip">Instagram</div>
  </a>
  <a href="https://www.facebook.com/profile.php?id=61560261904976" className="social-icon social-icon--facebook">
    <i className="fa fa-facebook"></i>
    <div className="tooltip">Facebook</div>
  </a>
  <a href="mailto:Cflaska@castleexplorerstravel.com
" className="social-icon social-icon--linkedin">
    <i className="fa fa-envelope"></i>
    <div className="tooltip">Email</div>
  </a>
</div>
</div>
                    </div>
                </div>
                <div 
                data-aos="fade-up"
                data-aos-delay="200"
                data-aos-duration="800"
                data-aos-easing="ease-in-out"
                data-aos-mirror="true"
                data-aos-once="true"
                data-aos-anchor-placement="top-bottom"
                id='aboutme'
                className='subhero' 
                style={{display:'flex', justifyContent:'space-around',alignItems:'center',padding:'1em',background:'url(https://bvfs.net/wp-content/uploads/2017/08/Cloudy-Sky-BG.png)',backgroundRepeat:'no-repeat',backgroundSize:'cover'}}>
                 <div className='flex column caption'>
                  <img src="https://dl3.pushbulletusercontent.com/FfQ8XwtoonwZxbhtEU767pbQJaH4SCPr/courtcastlemainpic.jpeg" alt="" />
                  <span>Courtney F.</span>
                  <span>Travel Advisor</span>
                 </div>
                    <p style={{width:'50vw'}}>Hello there! As a dedicated travel advisor, I specialize in planning magical and memorable vacations, focusing particularly on Disney, Universal, and other popular destinations</p>
                </div>

                {/* <!-- button --> */}
             

            </div>
        </section>
        {/* #_#_#_#_#_#_#_#_ */}
      
        {/* #_#_#_#_#_#_#_#_ */}
        <br />
        <div className="text-ready"
         data-aos="fade-up"
         data-aos-delay="200"
         data-aos-duration="800"
         data-aos-easing="ease-in-out"
         data-aos-mirror="true"
         data-aos-once="true"
         data-aos-anchor-placement="top-bottom">
        <h3>Ready to plan a trip? My name is Courtney Flaska, travel advisor with Castle Explorers and I am here to help!</h3>
        <h5>Just fill out this information so I can start working on a personalized quote for you!</h5>
        <br />
                               <a href="https://castleexplorers.typeform.com/courtneyflaska">
                                 <button className="btn btn-style-three"><b>Get a Free Quote </b></button>
                                </a>
                                <br />
        </div>
        <span style={{margin:'auto',textAlign:'center',padding:'1.32em 1.5em 0 1.5em'}}>Email:   <a style={{color:'darkblue',textDecoration:'underline'}} href="mailto:Cflaska@castleexplorerstravel.com
">Cflaska@castleexplorerstravel.com
</a></span>

        <br /><br />
   
        <br /><br />
   
        <br /><br />

     <section style={{display:'flex',flexDirection:'column',justiftContent:'center',alignItems:'center'}}
     data-aos="fade-right"
     data-aos-delay="0"
     data-aos-duration="800"
     data-aos-easing="ease-in-out"
     data-aos-mirror="true"
     data-aos-once="true">
        {/* <img src="https://external-content.duckduckgo.com/iu/?u=http%3A%2F%2Fblog.timesunion.com%2Fsavings%2Ffiles%2F2013%2F06%2Fwalt-disney-world-icons-1000x450.jpg&f=1&nofb=1&ipt=5be521cbcef79663cc91fe22d4e0e9897374f4732731cd0cbed26aef637473d2&ipo=images" alt="" /> */}
        <img src="https://s-media-cache-ak0.pinimg.com/originals/1c/ab/f6/1cabf60832ed23825dd4967c87c8c8b3.jpg" alt="" />
       </section>
        <div className="grid-body"
         data-aos="fade-left"
         data-aos-delay="0"
         data-aos-duration="500"
         data-aos-easing="ease-in-out"
         data-aos-mirror="true"
         data-aos-once="true">
        <section className="cards" 
        id='blog'
        >
<article
onClick={()=>{setPageCurrent('blogOne');window.scrollTo(0,0)}}
 className="cardi card--1">
  <div className="card__info-hover">
    {/* <svg className="card__like"  viewBox="0 0 24 24">
    <path fill="#000000" d="M12.1,18.55L12,18.65L11.89,18.55C7.14,14.24 4,11.39 4,8.5C4,6.5 5.5,5 7.5,5C9.04,5 10.54,6 11.07,7.36H12.93C13.46,6 14.96,5 16.5,5C18.5,5 20,6.5 20,8.5C20,11.39 16.86,14.24 12.1,18.55M16.5,3C14.76,3 13.09,3.81 12,5.08C10.91,3.81 9.24,3 7.5,3C4.42,3 2,5.41 2,8.5C2,12.27 5.4,15.36 10.55,20.03L12,21.35L13.45,20.03C18.6,15.36 22,12.27 22,8.5C22,5.41 19.58,3 16.5,3Z" />
</svg> */}
      {/* <div className="card__clock-info">
        <svg className="card__clock"  viewBox="0 0 24 24"><path d="M12,20A7,7 0 0,1 5,13A7,7 0 0,1 12,6A7,7 0 0,1 19,13A7,7 0 0,1 12,20M19.03,7.39L20.45,5.97C20,5.46 19.55,5 19.04,4.56L17.62,6C16.07,4.74 14.12,4 12,4A9,9 0 0,0 3,13A9,9 0 0,0 12,22C17,22 21,17.97 21,13C21,10.88 20.26,8.93 19.03,7.39M11,14H13V8H11M15,1H9V3H15V1Z" />
        </svg><span className="card__time">15 min</span>
      </div> */}
    
  </div>
  <div className="card__img"></div>
  <a href="#" className="card_link">
     <div className="card__img--hover"></div>
   </a>
  <div className="card__info">
    <span className="card__category"> TIPS</span>
    <h3 className="card__title">Should You Use a Travel Agent in 2024?</h3>
    <span className="card__by">by <a href="#" className="card__author" title="author">...</a></span>
  </div>
</article>

  
<article
onClick={()=>{setPageCurrent('blogTwo');window.scrollTo(0,0)}}
 className="cardi card--2">
  <div className="card__info-hover">
    <svg className="card__like"  viewBox="0 0 24 24">
    <path fill="#000000" d="M12.1,18.55L12,18.65L11.89,18.55C7.14,14.24 4,11.39 4,8.5C4,6.5 5.5,5 7.5,5C9.04,5 10.54,6 11.07,7.36H12.93C13.46,6 14.96,5 16.5,5C18.5,5 20,6.5 20,8.5C20,11.39 16.86,14.24 12.1,18.55M16.5,3C14.76,3 13.09,3.81 12,5.08C10.91,3.81 9.24,3 7.5,3C4.42,3 2,5.41 2,8.5C2,12.27 5.4,15.36 10.55,20.03L12,21.35L13.45,20.03C18.6,15.36 22,12.27 22,8.5C22,5.41 19.58,3 16.5,3Z" />
</svg>
  
    
  </div>
  <div className="card__img"></div>
  <a href="#" className="card_link">
     <div className="card__img--hover"></div>
   </a>
  <div className="card__info">
    <span className="card__category"> Travel</span>
    <h3 className="card__title">Who is Castle Explorers &amp; What are the benefits?</h3>
    <span className="card__by"> <a href="#" className="card__author" title="author"></a></span>
  </div>
</article>  
  
  
  
  </section>

  



     </div>


     {/*  */}
        <section>
            <div className="back text-white center">
                <div style={{paddingTop: '110px'}}>
                    <h1 style={{fontSize: '5vw',fontWeight:'lighter'}}>Why Wait For<br />
                        Your Vacation?
                    </h1>
                    <br />
                    <br />
                    <a href="https://castleexplorers.typeform.com/courtneyflaska">
                      <button className="btn btn-md plan-btn text-white"><b>Plan Your Trip Now </b></button>
                    </a>
                </div>
            </div>
        </section><br />
     
        <section>
            <div className="back-2 text-white center">
                <div style={{paddingTop: '110px'}}>
                    <p style={{fontSize: '4vw', fontWeight: 'lighter'}}>Have Questions? <br/>
                        Feel Free to Reach Out!
                    </p>
                    <br />
                    <br />
                    <a href="https://www.facebook.com/profile.php?id=61560261904976">

                    <button className="btn btn-md plan-btn text-white"><b>Contact Me </b></button>
                    </a>
                </div>
            </div>
        </section>
        <section className='mobile-non-parallax'>
        
        <img style={{filter:'brightness(1.2)'}} src="https://dl3.pushbulletusercontent.com/oaR8hQPx2c4qmfoZMK3H9yrQkrTFjA10/image.png" alt="" /> 

        <img src="https://dl3.pushbulletusercontent.com/LjLpYcP3WC7oD8xZn6E9HCTBWJDlttBr/image.png" alt="" /> 
       
       </section>
    
        <footer className="bg-dark text-white text-white p-3 center">
            <span><i className="far fa-copyright"></i> Courtney Flaska - Castle Explorers All Rights Reserved | 
            </span>
        </footer>
    </main>
}
</StyledApp>
)
}
 
export default App
